import { useLocalStorage } from "usehooks-ts";

const LOCAL_STORAGE_KEY = "showStripeDisclaimer";
export function StripeDisclaimer() {
  const [show, setShow] = useLocalStorage(LOCAL_STORAGE_KEY, true);
  if (!show) {
    return null;
  }

  return (
    <>
      {/* background */}
      <div className="z-10 absolute h-screen w-full bg-slate-700 opacity-50"></div>
      <div className="z-20 absolute w-full h-full flex flex-col justify-center items-center">
        <div
          className="rounded-xl w-4/5 max-w-sm bg-purple-100 text-slate-600 flex flex-col p-8"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="text-xl mb-5">maintenance finished!</div>
          <div className="mb-4">your favorite penguin app has returned 😎</div>
          <div className="mb-4">
            before booking again here, ensure that your payment info has been
            added on the regular skatebowl website, to accommodate their new
            payment processor
          </div>

          <div className="flex justify-center">
            <button
              onClick={() => setShow(false)}
              className="w-3/5 h-10 bg-violet-300 rounded border-2 border-blue-200 flex items-center justify-center font-light"
            >
              quit yapping!
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
