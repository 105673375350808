import { get, post } from "./fetch";
import { getDefaultStore, atom, useAtomValue } from "jotai";
import _ from "lodash";
import { useEffect } from "react";
import { navigate } from ".";
import { useLocalStorage } from "usehooks-ts";
import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Sentry from "@sentry/react";

const ENV = {
  sandboxKey: "sbx~00~300",
  bizId: "biz~00~CQcAAAAAAAA~YAQ",
  orgId: "org~YB~CQcAAAAAAAA~YQQ",
  configKey: "cfs~00~qg8AAAAAAAA~rzA",
};

type UserProxy = {
  // and a lot more stuff
  // pac key
  clientAccountKey: string;
  // pam key
  objKey: string;
};

export const authStateAtom = atom<
  (AuthPOSTResponse & { authedAt: Date }) | null
>(null);
function getAuthState() {
  return getDefaultStore().get(authStateAtom);
}
(window as any).simulateAuthExpiry = () => {
  const authState = getAuthState();
  if (authState) {
    getDefaultStore().set(authStateAtom, {
      ...authState,
      authedAt: new Date(0),
      authToken: "expired",
    });
  }
};

// A wrapper for "JSON.parse()"" to support "undefined" value
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.log("parsing error on", { value });
    return undefined;
  }
}

export function useAuthenticate() {
  // Whether we should attempt a login with the current username/password
  const [attemptLogin] = useLocalStorage("attemptLogin", false);

  useEffect(() => {
    if (attemptLogin) {
      console.log("Auto-authenticating with last credentials...");
      authenticate();
    } else {
      console.log("Redirecting to auth page");
      failAuth();
    }
  }, [attemptLogin]);

  useEffect(() => {
    const retryAuth = () => {
      if (document.visibilityState === "hidden") {
        return;
      }
      const lastAuthed = getAuthState()?.authedAt;
      if (lastAuthed) {
        const msSinceLastAuth = new Date().getTime() - lastAuthed.getTime();
        // Authenticated within last 3 minutes
        if (msSinceLastAuth < 1000 * 60 * 3) {
          return;
        }
      }
      authenticate(undefined, true);
    };
    document.addEventListener("visibilitychange", retryAuth);
    return () => {
      document.removeEventListener("visibilitychange", retryAuth);
    };
  });
}

function failAuth() {
  localStorage.setItem("attemptLogin", "false");
  getDefaultStore().set(authStateAtom, null);
  navigate("/auth");
}

let ongoingAuth: Promise<AuthPOSTResponse> | null = null;
export async function authenticate(
  redirect?: string,
  forceReauth = false
): Promise<AuthPOSTResponse> {
  const authState = getDefaultStore().get(authStateAtom);
  if (authState && !forceReauth) {
    return authState;
  }
  if (ongoingAuth) {
    return ongoingAuth;
  }
  console.log("Authenticating...");

  const email = parseJSON(localStorage.getItem("username")) as string;
  const password = parseJSON(localStorage.getItem("password")) as string;
  if (!email || !password) {
    throw new Error("Missing username/password");
  }
  getDefaultStore().set(authStateAtom, null);
  ongoingAuth = authPOST(email, password);
  try {
    // succeed auth
    const data = await ongoingAuth;
    localStorage.setItem("attemptLogin", "true");
    getDefaultStore().set(authStateAtom, { ...data, authedAt: new Date() });
    Sentry.setUser({
      username: email,
    });
    if (redirect) {
      navigate(redirect);
    }
    ongoingAuth = null;
    return data;
  } catch (e) {
    failAuth();
    ongoingAuth = null;
    throw e;
  }
}

/**
 * An account can have multiple members. We want to allow users to switch what
 * member is "focused", and to have that persist across refreshes. Store the
 * memberKey inside localStorage, alongside auth state. If localStorage reads
 * null, then we assume we'd like to use the default member on the account.
 */
export function useSelectedMemberKey() {
  const authState = useAtomValue(authStateAtom);
  const [_selectedMemberKey, setSelectedMemberKey] = useLocalStorage<
    string | null
  >("selectedMemberKey", null);
  const selectedMemberKey =
    _selectedMemberKey || authState?.environmentInfo?.userProxy.objKey || null;
  return [selectedMemberKey, setSelectedMemberKey] as const;
}

const SKATEBOWL = "https://skatebowl.com";

function onPromiseTimeout(promise: Promise<any>, ms: number, cb: () => void) {
  let done = false;
  promise.finally(() => {
    done = true;
  });
  setTimeout(() => {
    if (!done) {
      cb();
    }
  }, ms);
}

export type AuthPOSTResponse = {
  authToken: string;
  environmentInfo: {
    userProxy: UserProxy;
  };
  user: {
    scopeAccess: Array<{
      roleKeys: string[];
    }>;
    firstName: string;
    lastName: string;
  };
};
export async function authPOST(
  username: string,
  password: string
): Promise<AuthPOSTResponse> {
  const authPromise = post(
    `${SKATEBOWL}/auth/authorization`,
    {
      sandboxKey: ENV.sandboxKey,
    },
    {
      username,
      password,
      siteID: "skatebowl",
      allowedNavKey: "",
    }
  );
  onPromiseTimeout(authPromise, 8 * 1000, () => {
    toast("😓 still waiting...");
  });
  return authPromise;
}

type ClientAccount = {
  // A lot more stuff
  accountId: string;
  ownerAccountMemberKey: string;
  ownerEmailView: string;
  objType: "ClientAccount";
};
type AccountMember = {
  // pam key
  objKey: string;
  objType: "AccountMember";
  person: {
    firstName: string;
    lastName: string;
  };
};
export async function accountGET(): Promise<{
  clientAccount: ClientAccount;
  accountMembers: Array<AccountMember>;
}> {
  const token = (await authenticate()).authToken;
  const clientAccountKey =
    getAuthState()?.environmentInfo.userProxy.clientAccountKey;
  const data = await get(
    `${SKATEBOWL}/sandboxes/${ENV.sandboxKey}/account/${clientAccountKey}`,
    {},
    token
  );
  return {
    clientAccount: data.clientAccount,
    accountMembers: data.accountMembers,
  };
}

// Gets sessions available
export type TSession = {
  // A "choice" is a session for a day of the week that may repeat across a time
  // period (i.e. month)
  choice: {
    activeEnrollment: number;
    cost: number;
    costPerMeeting: number;
    costPerSession: number;
    course: {
      name: string;
    };
  };
  // A "choice" has several meeting dates across that time period
  meetingDates: {
    // id -> date string
    [id: string]: string;
  }[];
  // "HH:MM" format
  meetingStartTime: string;
};
export async function sessionsPOST(): Promise<TSession[]> {
  const authState = await authenticate();
  const userProxy = authState.environmentInfo.userProxy;
  const token = authState.authToken;
  const objKey = userProxy?.objKey;
  return await post(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/choicesForParticipant/${ENV.bizId}/${objKey}`,
    {},
    {
      choiceFetchedRels: ["course", "location"],
      currentChoiceSelections: [],
      includeCurrent: true,
      participant: userProxy,
      lightweightObjects: true,
    },
    token
  );
}

export type Meeting = {
  cost: number;
  maxEnrollment: number;
  // # of people signed up for session!
  currentEnrollment: number;
  // Whether spot's already reserved by me
  reserved: boolean;
  // Whether spot's already added by me
  added: boolean;
  meeting: {
    // YYYY-MM-DD format
    date: string;
    attendance: Array<any>;
    objKey: string;
    classKey: string;
  };
};

type TimeSlot = {
  // i.e. 60/90-min
  courseName: string;
  // i.e. Freestyle
  locationName: string;

  // HH:MM
  startTime: string;
  endTime: string;
  // instances of timeslots, distinguished by meeting.date
  meetings: Array<Meeting>;
};

export type TMultiClassSchedule = {
  courses: Array<any>;
  days: Array<{
    // i.e. 1 (Monday)
    dayNumber: number;
    // i.e. 7:45am, 9am
    timeSlots: Array<TimeSlot>;
  }>;
};
export async function multiClassScheduleGET(): Promise<TMultiClassSchedule> {
  const token = (await authenticate()).authToken;
  const clientAccountKey =
    getAuthState()?.environmentInfo.userProxy.clientAccountKey;
  return await get(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/clients/${clientAccountKey}/multiClassSchedule`,
    {
      orgKey: ENV.orgId,
      configKey: ENV.configKey,
    },
    token
  );
}

export type TMultiClassTimeSlot = Array<TimeSlot>;
export async function multiClassTimeSlotsPOST(
  // 1 for Monday, 2 for Tuesday, etc.
  day: number
): Promise<TMultiClassTimeSlot> {
  const token = (await authenticate()).authToken;
  const clientAccountKey =
    getAuthState()?.environmentInfo.userProxy.clientAccountKey || "";
  return await post(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/clients/${clientAccountKey}/multiClassTimeSlotsForWeekday/${day}`,
    {
      orgKey: ENV.orgId,
      configKey: ENV.configKey,
      accountMemberKey: clientAccountKey,
      disableLevels: "true",
    },
    // empty body
    {},
    token
  );
}

// Differs from StripeBillingGETResponse -- one such discrepancy is that
// StripeBillingGETResponse has `name` field instead of `firstName`/`lastName`
export type BillingInfo = {
  id: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: string;
  company: "";
  extendedAddress: "";
  country: "";
  stripe: {
    // i.e. pm_XXXX
    paymentMethodId: string;
    // i.e. cus_XXXX
    customerId: string;
  };
};

export type StripeBillingGETResponse = {
  // i.e. pm_XXXXX
  id: string;
  // i.e. cus_XXXXX
  customerVaultId: string;
  // Note: `name` field instead of `firstName`/`lastName`
  name: string;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
};

// Used to get BillingInfo and billingId
export async function billingEntriesGET(): Promise<BillingInfo> {
  const authState = await authenticate();
  const objKey = authState.environmentInfo.userProxy.objKey || "";
  const token = authState.authToken;
  const data = await get(
    `${SKATEBOWL}/payments/sandboxes/${ENV.sandboxKey}/stripeBillingEntries/Organization/${ENV.orgId}/AccountMember/${objKey}`,
    {},
    token
  );
  const resp = data[0] as StripeBillingGETResponse;
  return {
    id: resp.id,
    firstName: authState.user.firstName,
    lastName: authState.user.lastName,
    streetAddress: resp.address1,
    locality: resp.city,
    region: resp.state,
    postalCode: resp.postalCode,
    company: "",
    extendedAddress: "",
    country: "",
    stripe: {
      paymentMethodId: resp.id,
      customerId: resp.customerVaultId,
    },
  };
}

// Returns transactionId
export async function initiateTransactionGET(): Promise<string> {
  const token = (await authenticate()).authToken;
  return await get(
    `${SKATEBOWL}/sysapi/transaction`,
    { sandboxKey: ENV.sandboxKey },
    token
  );
}

// Users can have credits or "prepaid balances" in their accounts. Both of these
// can serve as payments for a session.
export type EligibleBalancesPOST = {
  prepaidBalances: Array<{
    balance: number;
    eligibleAmount: number;
    fund: {
      name: string;
      objKey: string;
      // "After # days", "On specific date", "Never"
      expirationType: string;
      expirationValue: Date;
    };
  }>;
  creditBalance: number;
};
export async function pricingEligibleBalancesPOST(
  cost: number
): Promise<EligibleBalancesPOST> {
  const authState = await authenticate();
  const objKey = authState?.environmentInfo.userProxy.objKey;
  const token = authState.authToken;
  const data: EligibleBalancesPOST = await post(
    `${SKATEBOWL}/accounting/sandboxes/${ENV.sandboxKey}/pricingEligibleBalances/AccountMember/${objKey}`,
    {},
    // Not sure if this param matters?
    [{ baseCost: cost }],
    token
  );
  data.prepaidBalances.forEach((b) => {
    b.fund.expirationValue = new Date(b.fund.expirationValue);
  });
  return data;
}

// unused?
export async function pricingPOST(
  transactionId: string,
  meetingKey: string,
  classKey: string,
  // these 2 are from accountGET
  clientAccount: ClientAccount,
  accountMember: AccountMember
): Promise<any> {
  const authState = await authenticate();
  const clientAccountKey = authState.environmentInfo.userProxy.clientAccountKey;
  const roleKeys = authState?.user.scopeAccess[0].roleKeys || [];
  await post(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/pricing`,
    {},
    {
      addOnProducts: [],
      businessKey: ENV.bizId,
      clientAccountKey,
      notificationTemplateKey: "",
      participants: [
        {
          ...accountMember,
          selections: [
            {
              meetingKeys: [meetingKey],
              classKey: classKey,
              selectionType: "Class",
              dropin: false,
              prepaid: false,
              installmentPlan: null,
            },
          ],
        },
      ],
      roleKeys,
      transactionId,
      trxRequestPayments: null,
      userAccount: clientAccount,
      userProxy: authState?.environmentInfo.userProxy,
    },
    authState.authToken
  );
}

export type PaymentMethod =
  | {
      type: "creditCard" | "credits";
      amount: number;
    }
  | {
      type: "prepaidBalance";
      amount: number;
      prepaidFundKey: string;
    };
export type PaymentMethods = Array<PaymentMethod>;
// Completes the purchase flow to enroll
async function enrollPOST(
  meetingKey: string,
  classKey: string,
  timeString: string,
  paymentMethods: PaymentMethods,
  // these 2 below are from accountGET
  clientAccount: ClientAccount,
  // this is the actual person who's being reserved for. if an account has
  // multiple members, this distinction matters.
  accountMember: AccountMember,
  transactionId: string,
  billingInfo: BillingInfo
) {
  const authState = await authenticate();
  let preAuth = undefined;
  const ccPayment = paymentMethods.find((p) => p.type === "creditCard");
  // May be >1 prepaid payments
  const prepaidPayments = paymentMethods.filter(
    (p): p is Extract<PaymentMethod, { type: "prepaidBalance" }> =>
      p.type === "prepaidBalance"
  );
  const creditsPayment = paymentMethods.find((p) => p.type === "credits");

  const trxRequestPayments = [];
  if (ccPayment) {
    preAuth = {
      paymentGateway: "stripe",
      paymentMethod: "creditCardOrOther",
      paymentMethodToken: billingInfo.stripe.paymentMethodId,
      // Apparently SkateBowl also leaves this blank -- yet Stripe still accepts
      // this without a payment intent id??
      tokenId: "",
      totalAmount: ccPayment.amount,
      userBillingInfo: billingInfo,
      waiveFee: false,
    };
    trxRequestPayments.push({
      amount: ccPayment.amount,
      gatewayBillingInfo: billingInfo,
      gatewayEmailAddress: clientAccount.ownerEmailView,
      gatewayPreauthResult: preAuth,
      paymentId: "gateway",
      paymentMethodDetails: "",
      prepaidFundKey: "",
    });
  }
  if (prepaidPayments.length > 0) {
    prepaidPayments.forEach((pp) => {
      trxRequestPayments.push({
        amount: pp.amount,
        prepaidFundKey: pp.prepaidFundKey,
        paymentId: "ppd6",
        paymentMethod: "PrepaidFunds",
        paymentMethodDetails: "",
      });
    });
  }
  if (creditsPayment) {
    trxRequestPayments.push({
      amount: creditsPayment.amount,
      paymentId: "acc",
      paymentMethod: "ApplyCredit",
      paymentMethodDetails: "",
      prepaidFundKey: "",
    });
  }

  if (process.env.NODE_ENV === "development") {
    console.warn("🚧 Skipping purchase in development...");
    return;
  }

  await post(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/enroll`,
    {},
    {
      addOnProducts: [],
      notificationStyle: null,
      notificationTemplateKey: "",
      organizationKey: ENV.orgId,
      clientAccountKey: authState?.environmentInfo.userProxy.clientAccountKey,
      participants: [
        {
          ...accountMember,
          selections: [
            {
              meetingKeys: [meetingKey],
              classKey: classKey,
              selectionType: "Class",
              dropin: false,
              prepaid: false,
              installmentPlan: null,
            },
          ],
        },
      ],
      preAuthResult: preAuth,
      roleKeys: authState?.user.scopeAccess[0].roleKeys,
      transactionId,
      trxRequestPayments,
      userAccount: clientAccount,
      userProxy: authState?.environmentInfo.userProxy,
    },
    authState?.authToken
  );
  Sentry.captureEvent({
    message: "session booked",
    tags: {
      paymentMethods: JSON.stringify(paymentMethods),
      user: authState?.user.firstName,
      ownerEmailView: clientAccount.ownerEmailView,
      member: accountMember.person.firstName,
      timeString,
    },
  });
  // Keep in sync with localStorage key and logic in useRecentTimes in
  // QuickBook.tsx
  const recentBookings: string[] =
    parseJSON(localStorage.getItem("recentBookings")) || [];
  if (recentBookings.includes(timeString)) {
    recentBookings.splice(recentBookings.indexOf(timeString), 1);
  }
  recentBookings.unshift(timeString);
  localStorage.setItem("recentBookings", JSON.stringify(recentBookings));
}

type PaymentIntentPOSTResponse = {
  // i.e. pi_XXX_secret_XXX
  client_secret: string;
  // i.e. pi_3Q5MkVFqOzCzsODP1m3fV0vf
  payment_intent_ID: string;
  // customerId
  payment_system_ID: string;
};
export async function createPaymentIntentPOST(
  amount: number,
  billingInfo: BillingInfo
): Promise<PaymentIntentPOSTResponse> {
  const authState = await authenticate();
  const token = authState.authToken;
  const result: PaymentIntentPOSTResponse = await post(
    `${SKATEBOWL}/pos/sandboxes/${ENV.sandboxKey}/stripe/Organization/${ENV.orgId}/amount/${amount}/create_payment_intent`,
    {
      paymentMethods: "card",
      paymentSystemID: billingInfo.stripe.customerId,
    },
    {},
    token
  );
  return result;
}

type UserChoicesGETResponse = Array<{
  enrollment: {
    classKey: string;
    classMeetingKeys: Array<string>;
  };
}>;
export async function userChoicesGET(): Promise<UserChoicesGETResponse> {
  const authState = await authenticate();
  const token = authState.authToken;
  const objKey = authState.environmentInfo.userProxy.objKey;
  const result = await get(
    `${SKATEBOWL}/signup/sandboxes/${ENV.sandboxKey}/userChoices/${ENV.bizId}`,
    {},
    token
  );
  return result.enrolledChoices[objKey || ""] || [];
}

async function bookSession(args: {
  session: Meeting;
  timeString: string;
  paymentMethods: PaymentMethods;
  selectedMemberKey: string;
}): Promise<PaymentMethods> {
  const { session, timeString, paymentMethods } = args;
  const cost = session.cost;
  const paying = _.sumBy(paymentMethods, "amount");
  if (paying !== cost) {
    throw new Error(`Expected to pay ${cost}, but paying ${paying}`);
  }
  const { classKey, objKey: meetingKey } = session.meeting;

  const [billingInfo, transactionId, { clientAccount, accountMembers }] =
    await Promise.all([
      billingEntriesGET(),
      initiateTransactionGET(),
      accountGET(),
    ]);

  const ccPayment = paymentMethods.find((p) => p.type === "creditCard");
  if (ccPayment) {
    // I'm really not sure why this is even needed -- we don't even capture the
    // payment intent ID and send it to Stripe... perhaps Stripe or Skatebowl can
    // reconcile the payment intent ID with the payment method ID?
    await createPaymentIntentPOST(ccPayment.amount, billingInfo);
  }

  let accountMember = accountMembers.find(
    (m) => m.objKey === args.selectedMemberKey
  );
  if (!accountMember) {
    Sentry.captureEvent({
      message: "no accountMember found, falling back to default",
      tags: {
        memberKeys: JSON.stringify(accountMembers.map((m) => m.objKey)),
        selectedMemberKey: args.selectedMemberKey,
      },
    });
    accountMember = accountMembers[0];
  }

  await enrollPOST(
    meetingKey,
    classKey,
    timeString,
    paymentMethods,
    clientAccount,
    accountMember,
    transactionId,
    billingInfo
  );

  return paymentMethods;
}

export function useAllBookedMeetings() {
  return useQuery(
    "allBookedMeetings",
    async () => {
      const result = await userChoicesGET();
      const allMeetingKeys = result.reduce<string[]>((acc, { enrollment }) => {
        acc.push(...enrollment.classMeetingKeys);
        return acc;
      }, []);
      return allMeetingKeys;
    },
    { refetchOnWindowFocus: true }
  );
}

export function useBookMeetingMutation() {
  const queryClient = useQueryClient();
  const [selectedMemberKey] = useSelectedMemberKey();

  const mutation = useMutation({
    mutationFn: async (args: {
      session: Meeting;
      timeString: string;
      paymentMethods: PaymentMethods;
    }) => {
      if (!selectedMemberKey) {
        throw new Error("No selected member key");
      }
      const promise = await bookSession({ ...args, selectedMemberKey });
      queryClient.refetchQueries("multiTimeSlot");
      queryClient.refetchQueries("allBookedMeetings");
      setTimeout(() => {
        mutation.reset();
      }, 5000);
      return promise;
    },
  });
  return mutation;
}
