import { EligibleBalancesPOST, PaymentMethods } from "./api";
import * as Sentry from "@sentry/react";

export const dayToString: { [d: number]: string } = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
};

// Date -> 6:30am / 5:00pm format
export function dateToShortTime(date: Date) {
  const h = date.getHours();
  const m = date.getMinutes();
  const ampm = h >= 12 ? "pm" : "am";

  // Add leading zero to minutes if they are less than 10
  const formattedMinutes = m < 10 ? "0" + m : m;

  return `${h % 12 || 12}:${formattedMinutes}${ampm}`;
}

// Date -> "thu @ 6:30am"
export function dateToShortStr(date: Date) {
  return `${dayToString[date.getDay()]} @ ${dateToShortTime(date)}`;
}

export function getOptimalPaymentMethods(
  total: number,
  eligibleBalances: EligibleBalancesPOST
): PaymentMethods {
  // Priority: Prepaid Balance -> Credits -> Credit Card
  // Constraint: Cannot pay with multiple types of credits / prepaid balances
  const paymentMethods: PaymentMethods = [];
  let usedPrepaid = false;

  // Filter for valid balances + sort prepaidBalances by soonest expiration date
  const prepaidBalances = eligibleBalances.prepaidBalances
    .filter((b) => {
      if (b.balance <= 0) {
        return false;
      }

      let { expirationType, expirationValue } = b.fund;
      expirationType = expirationType.toLowerCase();
      if (expirationType === "never") {
        return true;
      } else if (expirationType === "after # days") {
        // I can't find where # days is sent down. However, there is another
        // field "balancesByExpiration" on the `balance` object that contains a
        // JSON mapping of expiration date -> balance. Worst case, we can use
        // that object if this breaks.
        return true;
      } else if (expirationType === "on specific date") {
        return expirationValue > new Date();
      } else {
        Sentry.captureException({
          message: "unknown expirationType for prepaidBalance",
          tags: {
            expirationType,
          },
        });
      }

      // Unknown value of expirationType, just return true
      return true;
    })
    .sort((a, b) => {
      return a.fund.expirationValue > b.fund.expirationValue ? 1 : -1;
    });

  if (prepaidBalances.length > 0 && total > 0) {
    const p = prepaidBalances[0];
    const amount = Math.min(total, p.balance);
    paymentMethods.push({
      type: "prepaidBalance",
      amount,
      prepaidFundKey: p.fund.objKey,
    });
    usedPrepaid = true;
    total -= amount;
  }

  if (
    // Weirdly enough, creditBalance is stored as a negative number
    eligibleBalances.creditBalance < 0 &&
    total > 0 &&
    !usedPrepaid
  ) {
    const amount = Math.min(total, -eligibleBalances.creditBalance);
    paymentMethods.push({
      type: "credits",
      amount,
    });
    total -= amount;
  }

  if (total > 0) {
    paymentMethods.push({
      type: "creditCard",
      amount: total,
    });
  }

  return paymentMethods;
}
