import { useAuthenticate } from "./api";
import { QuickBook } from "./QuickBook";
import { Header, Page } from "./components";
import { Schedule } from "./Schedule";
import { Maintenance } from "./Maintenance";
import { createPortal } from "react-dom";
import { BookingModal, bookingModalInfoAtom } from "./BookingModal";
import { useAtomValue } from "jotai";
import { GeneralDisclaimer } from "./Disclaimer";
import { useEffect, useState } from "react";
import { StripeDisclaimer } from "./StripeDisclaimer";

const inMaintenance = false;
function App() {
  useAuthenticate();
  const [pageElement, setPageElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setPageElement(document.getElementById("page")!);
  }, []);

  const bookingModalInfo = useAtomValue(bookingModalInfoAtom);
  if (inMaintenance) {
    return (
      <Page>
        <Maintenance />
      </Page>
    );
  }

  return (
    <Page>
      {pageElement && createPortal(<StripeDisclaimer />, pageElement)}
      {pageElement && createPortal(<GeneralDisclaimer />, pageElement)}
      <Header />
      <QuickBook />
      <div className="mb-4" />
      <Schedule />
      {bookingModalInfo && createPortal(<BookingModal />, pageElement!)}
    </Page>
  );
}

export default App;
